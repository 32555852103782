import $ from 'jquery'

class Booking {
    constructor() {
        this.elements = {
            hotel_options: $('.js-booking-hotels'),
            hotel_options_bar: $('.js-booking-form-input[name="hotel"]'),
            steps: $('.js-booking-step'),
            step_links: $('.js-booking-step-link'),
            reset_button: $('.js-reset-booking'),
            check_in: $('.js-booking-form-check-in'),
            check_out: $('.js-booking-form-check-out'),
            submit: $('.c-booking-form__submit'),
            hotels_content: $('.js-booking-hotel-content'),
            hotelClassList: [],
            manageBooking: $('.c-offcanvas__manage-booking'),
        }

        /** 
         * used to hold hotel specific data 
         * - currently used to set specific opening dates
         * ! Do not delete/comment out the this.newHotels = [], simply comment out the inner objects 
         * this is due to our enviroment not supporting the new object optional chaining "?."
         */
        this.newHotels = [
            {
                name: 'brighton',
                locationId: 45467,
                openingDate: '2024-10-09',
                hotelTargetSelector: '.t-no-124-brighton',
            }
        ]
        
        this.initCustomDateDisplay()
        this.initHotelSwitcher()
        this.initSteps()
        this.initResetButton()
        this.removeSearchParam()
    }
    initHotelSwitcher(){
        var allEls = document.querySelectorAll('.s-page-wrap .c-booking-form select.js-booking-form-input');
        for (var i = 0; i < allEls.length; i++) {
            allEls[i].required = 'required';
        }

        this.elements.hotel_options_bar.add(this.elements.hotel_options).each((key, element) => {
            this.updateLocationValue(element)
        });

        this.elements.hotel_options.on('change', (e) => {
            let _this = e.currentTarget
            let hotel_id = _this.options[_this.selectedIndex].value
            let hotel_name = _this.options[_this.selectedIndex].text.replace(/[^A-Z0-9]/ig, "-").toLowerCase()
            if(!this.elements.hotelClassList.includes(hotel_name)) {
                this.elements.hotelClassList.push(hotel_name)
            }

            let hotel_options = this.elements.hotels_content.filter((i, hotel) => {
                return hotel.dataset.hotelLink && (hotel.dataset.hotelLink == hotel_id)
            })

            this.elements.steps.find(this.elements.hotel_options_bar).each(function() {
                $(this).val(hotel_id).trigger('change')
            });
            
            this.elements.steps.add(this.elements.hotels_content).removeClass('is-active')
            $(_this).parents('.js-booking-step').next('.js-booking-step').add(hotel_options).addClass('is-active')
            this.elements.submit.removeClass(this.elements.hotelClassList.join(' '))
            this.elements.submit.addClass(hotel_name)

            this.elements.manageBooking.each(function(){
                var item = $(this);
                var manageID = item.attr('data-hotel-link')
                
                if(hotel_id == manageID ) {
                    item.siblings().removeClass('is-active')
                    item.removeClass('is-active').addClass('is-active')
                }
            })
        })

        this.elements.hotel_options_bar.on('change', ({target}) => {
            if (!this.newHotels.length > 0) return
            // get the changed options bar's checkin field to update its specific dates
            let asociatedCheckInField = $(target).closest('.c-booking-form__field--hotel').siblings('.c-booking-form__field--check-in').find('.js-booking-form-check-in')

            this.updateCheckinDates($(asociatedCheckInField), target)
        })
    }
    initSteps(){
        this.elements.step_links.on('click', (e) => {
            let step = e.currentTarget.dataset.step
            if(step.length > 0){
                e.preventDefault()
                $(this.elements.steps).removeClass('is-active').filter('[data-step='+ step +']').addClass('is-active')
            }
        })
    }
    initCustomDateDisplay(){
        if(this.elements.check_in.length == 0) return;

        let dates = this.elements.check_in.add(this.elements.check_out)

        dates.each((i, el) => {
            $(el).after($('<p class="c-booking-form__custom-date  js-custom-date-display"></p>'))
        })
        
        dates.on('change.custom-date-display', (e) => {
            let _this = e.currentTarget
            let date = _this.value
            let day = date.split(' ')[0]
            let month = date.split(' ')[1]

            $(_this).next('.js-custom-date-display').html('<span class="c-booking-form__day">' + day + '</span><span class="c-booking-form__month">' + month + '</span>')
        })

        this.updateCheckinDates()
    }
    initResetButton(){
        this.elements.reset_button.on('click.reset', () => {
            $(this.elements.steps).add(this.elements.hotels_content).removeClass('is-active').filter(':nth-of-type(1)').addClass('is-active')
            this.elements.submit.removeClass(this.elements.hotelClassList.join(' '))

            if(this.elements.hotel_options.length > 0) {
                this.elements.hotel_options.find('option').prop('selected', function() {
                    return this.defaultSelected
                });
            }
        })
    }
    updateLocationValue(element) {
        let options = $(element).find('option')

        options.eq(0).text('Select your destination')

        options.each((i, opt) => {
            if(HOTEL_BOOKING_DATA[opt.value]){
                opt.disabled = !HOTEL_BOOKING_DATA[opt.value].open

                if(!opt.disabled){
                    opt.selected = HOTEL_BOOKING_DATA[opt.value].current
                }
            }
        })
    }
    updateCheckinDates(element, formLocation) {
        let targets = (element) ? $(element) : this.elements.check_in,
            location = (formLocation) ? $(formLocation) : this.elements.hotel_options_bar

        targets.each((i, el) => {
            if (!this.newHotels.length > 0) return this.setHotelCheckInDate(el)
            // loop through newHotels, if property found attempt to set opening date : default to today
            this.newHotels.forEach(hotel => {
                if (location.val() == hotel.locationId || $(hotel.hotelTargetSelector).length > 0)
                    return this.setHotelCheckInDate(el, hotel.openingDate)

                this.setHotelCheckInDate(el)
            })
        })
    }
    setHotelCheckInDate(element = el, suppliedDate = null, {triggerEvent = 'change.custom-date-display'} = {}) {
        // set to today by default if no date passed / date has passed
        let dateHasPassed = false

        const setDate = ((suppliedDate) => {
            if (!suppliedDate) return new Date

            const today = new Date(),
                checkDate = new Date(suppliedDate)

            if (checkDate < today.setHours(0, 0, 0, 0)) {
                dateHasPassed = true
            }
            // default to today if date has passed
            return (dateHasPassed) ? today : checkDate;
        })(suppliedDate)

        const date = setDate

        $(element).pickadate('picker').set('select', + date).trigger(triggerEvent)

        if (!dateHasPassed) return

        const tomorrow = ((date) => {
            let suppliedDate = new Date(date),
                nextDayDate = suppliedDate.setDate(suppliedDate.getDate() + 1)

            return new Date(nextDayDate)
        })(date)

        $(this.elements.check_out).each(function() {
            $(this).pickadate('picker').set('select', + tomorrow).trigger(triggerEvent)
        })
    }
    removeSearchParam() {
        if(document.location.search == '?booking-modal=true'){
            window.history.replaceState({}, document.title, window.location.href.split("?")[0])
        }
    }
}

export default Booking
