import Menu from './modules/Menu'
import Booking from './modules/Booking'
import StoriesGallery from './modules/StoriesGallery'
import ApplyDelays from './modules/ApplyDelays'

$('.c-media--layout-content-left.c-media--stacked-images').each(function () {
    new ApplyDelays(this, '.c-media__image', .75, .75)
})

new Menu()
$(function(){
    new Booking()
});

$('.c-grid-filter').filter((i, el) => $(el).find('.c-grid-filter__filter').length == 0 ).removeAttr('data-module')

$(document).ready(function () {
    $(window).on("resize", function (e) {
        checkScreenSize();
    });

    checkScreenSize();

    function checkScreenSize(){
        if($('.c-media-group .c-media-group__items').length) {
            var newWindowWidth = $(window).width();

            if (newWindowWidth <= 767) {
                $('.c-media-group .c-media-group__items').flickity({
                    // options
                    cellAlign: 'left',
                    contain: true
                });
            }
            else {
                if ($('.c-media-group .c-media-group__items.js-media-group-items.flickity-enabled.is-draggable').length) {
                    $('.c-media-group .c-media-group__items').flickity('destroy')
                }
            }
        }
    }

    $('.c-grid-filter--stories').each(function(){
        new StoriesGallery($(this))
    })

});

// Transfer tool JS

$('.ht-tab-header__trigger').on('click', function() {
    $('.ht-tab').toggleClass('revealed');
    $('.ht-tab-header').toggleClass('revealed');
    $('.ht-tab-header__heading').toggleClass('revealed');
    $('.ht-tab-header__icon').toggleClass('revealed');
    $('.ht-tab-header__close').toggleClass('revealed');
    $('.ht-tab-body').toggleClass('revealed');
  });
