import $ from 'jquery'

class Menu {
    constructor() {
        this.elements = {
            menu: $('.c-nav-primary'),
            menuToggles: $('.c-nav-primary').find('.js-toggle-menu'),
            subMenuToggles: $('.c-nav-primary').find('[class*=--has-children] > a'),
            hotelNavDropdowns: $('.js-hotel-nav').find('[class*=--has-children]'),
            activeMenuTitle: $('.js-active-menu'),
            activeItem: $('.c-nav-primary__root-menu > li:first-child() .sub-menu a').filter((i, el) =>
                new URL(el.href, "https://guesthousehotels.co.uk").pathname ===
                location.pathname && location.pathname != '/'
            )
        }

        this.setMenuToggles()
        this.setInitialState()
    }

    setMenuToggles() {
        this.elements.menuToggles.on('click.menus', (e) => {
            let _this = $(e.currentTarget)

            $('.is-sub-menu').removeClass('is-sub-menu')
            this.elements.activeMenuTitle.text('')
            this.toggleOpenClass($(e.currentTarget).parent())
        })

        this.elements.subMenuToggles.on('click.submenus', (e) => {
            let _this = $(e.currentTarget)

            e.preventDefault()

            _this.parents('.js-group-item').toggleClass('is-sub-menu')
            this.toggleOpenClass(_this.parent())
            this.elements.activeMenuTitle.text(_this.text())
        })

        this.elements.activeMenuTitle.on('click.activemenu', (e) => {
            let _this = $(e.currentTarget)

            _this.parents('.js-group-item')
                .toggleClass('is-sub-menu')
                .find('.is-open').removeClass('is-open')
        })


        this.elements.hotelNavDropdowns.on({
            'mouseover': (e) => {
                $(e.currentTarget).addClass('is-open')
            },
            'mouseout': (e) => {
                $(e.currentTarget).removeClass('is-open')
            }
        })
    }

    setInitialState() {

        // sub menu should be open initially if on child page
        if(this.elements.activeItem.length <= 2) {
            this.elements.activeItem.closest('.c-nav-primary__root-menu__item--has-children').addClass('is-open')
            this.elements.activeItem.closest('.c-nav-primary__root-menu > li').addClass('is-open')
        }
    }

    toggleOpenClass(parent){
        parent.toggleClass('is-open')
        parent.find('.is-open').removeClass('is-open')
        parent.siblings().removeClass('is-open')
        this.elements.menu.toggleClass('is-group-item', this.elements.menu.find('.is-open').length > 0)
    }
}

export default Menu
