import $ from 'jquery'

class StoriesGallery {
    constructor(grid) {
        let isotopeGrid = grid.find('.js-grid-container')

        isotopeGrid.isotope({
            stagger: 0,
            transitionDuration: '0'
        })

        grid.find('.js-filter').on('click.reLayout', function() {
            isotopeGrid.one('arrangeComplete', function() {
                setTimeout(() => {
                    $(this).isotope('layout')
                }, 500)
            })
        })

        grid.find('.c-cta').on({
            'touchstart': function(){
                $(this).addClass('is-active')
            },
            'touchend touchcancel': function() {
                $(this).removeClass('is-active')
            }
        })

        $(window).on('resize', function() {
            setTimeout(() => {
                isotopeGrid.isotope('layout')
            }, 500);
        }).trigger('resize')
    }
}

export default StoriesGallery