export default class ApplyDelays {
    constructor(el, target, delay = .3, multiplier = .1) {
        this.base = $(el)
        this.target = target
        this.items = this.base.find(this.target)
        this.delay = delay
        this.multiplier = multiplier

        this.applyDelays()
    }
    
    applyDelays() {        
        const delay = this.delay
        const multiplier = this.multiplier
        
        this.items.each(function(i) {
            let setDelay = `${(delay + (i * multiplier))}s`

            $(this).css('transition-delay', setDelay)
        })
    }
};